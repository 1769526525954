function initPhotoGalleries() {
    let galleries = document.querySelectorAll(".photo-galleries-section .photo-gallery .images");

    for (let i = 0; i < galleries.length; i++) {
        lightGallery(galleries[i], {
            download: false,
            licenseKey: "2B5AA114-5F7A43FC-9B047164-378F6188",
        });
    }
}
