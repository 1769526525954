(function () {
    var theme = {
        /* =====================================
         *  All pages
         * ===================================*/
        common: {
            init: function () {},
            finalize: function () {
                initMenu();
                initExpandingBoxes();
                initPhotoGalleries();

                AOS.init({
                    easing: "ease-in-out-quad",
                    delay: 100,
                    once: true,
                    duration: 500,
                    startEvent: "load",
                });
            },
        },
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function (func, funcname, args) {
            var fire;
            var namespace = theme;
            funcname = funcname === undefined ? "init" : funcname;
            fire = func !== "";
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === "function";

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function () {
            // Fire common init JS
            UTIL.fire("common");

            // Fire page-specific init JS, and then finalize JS
            var items = document.body.className.replace(/-/g, "_").split(/\s+/);
            items.forEach(
                () =>
                    function (i, classnm) {
                        UTIL.fire(classnm);
                        UTIL.fire(classnm, "finalize");
                    }
            );

            // Fire common finalize JS
            UTIL.fire("common", "finalize");
        },
    };

    // Load Events
    document.addEventListener("DOMContentLoaded", function (event) {
        UTIL.loadEvents();
    });
})();
