function initMenu() {
    let menuButton = document.querySelector("header .menu-button");
    menuButton.addEventListener("click", function () {
        document.querySelector("body").classList.toggle("menu-open");
    });

    let closeMenuButton = document.querySelector("header .close-button");
    closeMenuButton.addEventListener("click", function () {
        document.querySelector("body").classList.toggle("menu-open");
    });
}
